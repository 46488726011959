import { DateTime } from 'luxon';

import { DayOfWeek } from '@/types';

import { Availability, WorkingHourSettings } from '../types';

export const constrainAvailabilityToWorkingHours = (
  availability: Availability,
  workingHours: WorkingHourSettings,
): Availability => {
  const newAvailability: Availability = {
    1: [],
    2: [],
    3: [],
    4: [],
    5: [],
    6: [],
    7: [],
  };
  Object.entries(availability).forEach(([day, dayAvailability]) => {
    const workingHoursForDay = workingHours[day as DayOfWeek];
    if (!workingHoursForDay || workingHoursForDay.length !== 2) {
      return;
    }
    const [workingStart, workingEnd] = workingHoursForDay;
    newAvailability[day as DayOfWeek] = dayAvailability
      .map((block) => {
        workingHoursForDay;
        const { start, end } = block;
        const startTime = DateTime.fromFormat(start, 'HH:mm');
        const endTime = DateTime.fromFormat(end, 'HH:mm');
        const workingStartTime = DateTime.fromFormat(workingStart, 'HH:mm');
        const workingEndTime = DateTime.fromFormat(workingEnd, 'HH:mm');

        if (startTime >= workingEndTime || endTime <= workingStartTime) {
          return null;
        }

        const newStart = DateTime.max(startTime, workingStartTime);
        const newEnd = DateTime.min(endTime, workingEndTime);

        return {
          start: newStart.toFormat('HH:mm'),
          end: newEnd.toFormat('HH:mm'),
          type: block.type,
        };
      })
      .filter((block) => block !== null) as Availability[DayOfWeek];
  });
  return newAvailability;
};
