import { FC } from 'react';

import { Link, useLocation } from 'react-router-dom';

import { ROUTES_TO_ROUTE_DETAILS } from '@routes/constants';
import { PRIVATE_ROUTES } from '@routes/enums';
import { getActiveLink } from '@utils/getActiveLink';

import { NavLink } from './NavLink';
import { TooltipActionIcon } from './TooltipActionIcon';

interface NavLinksProps {
  isCollapsed?: boolean;
  onNavigate?: () => void;
}

export const NavLinks: FC<NavLinksProps> = ({ isCollapsed, onNavigate }) => {
  const location = useLocation();
  const activeLink = getActiveLink(Object.values(PRIVATE_ROUTES), location.pathname);

  return Object.values(PRIVATE_ROUTES)
    .filter((link) => !ROUTES_TO_ROUTE_DETAILS[link].hidden)
    .map((link) => {
      const routeDetails = ROUTES_TO_ROUTE_DETAILS[link];
      const IconComponent = routeDetails.icon;
      const ActiveIconComponent = routeDetails.activeIcon;

      const iconSize = isCollapsed ? '48px' : '20px';

      const isActive = link === activeLink;

      const icon = isActive ? (
        ActiveIconComponent ? (
          <ActiveIconComponent size={iconSize} stroke={0} />
        ) : (
          <IconComponent size={iconSize} stroke={2.5} />
        )
      ) : (
        <IconComponent size={iconSize} stroke={1.5} />
      );

      if (isCollapsed) {
        return (
          <TooltipActionIcon
            key={link}
            component={Link}
            to={link}
            title={routeDetails.title}
            icon={icon}
            onClick={onNavigate}
          />
        );
      }

      return (
        <NavLink
          key={link}
          label={routeDetails.title}
          to={link}
          active={isActive}
          leftSection={icon}
          onClick={onNavigate}
        />
      );
    });
};
