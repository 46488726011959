import { Stack } from '@mantine/core';
import { notifications } from '@mantine/notifications';
import { IconClockHour10 } from '@tabler/icons-react';

import { AnalyticsEventName, track } from '@features/analytics';

import { usePreferences } from '../api/getPreferences';
import { useUpdatePreferences } from '../api/updatePreferences';

import { MinutesSelection } from './MinutesSelection';
import { SettingsGrid } from './SettingsGrid';

export const MeetingDuration = () => {
  const { data: preferences } = usePreferences();
  const { mutate: updatePreferences } = useUpdatePreferences({
    onSuccess: () => {
      track({ type: AnalyticsEventName.PreferencesUpdated, data: { preference: 'duration' } });
      notifications.show({ message: 'Meeting duration updated', color: 'green' });
    },
  });

  const handleDurationSelect = (value: number) => {
    if (!preferences) return;

    updatePreferences({ ...preferences, duration: value });
  };

  const handleInPersonDurationSelect = (value: number) => {
    if (!preferences) return;

    updatePreferences({ ...preferences, inPersonDuration: value });
  };

  if (!preferences) {
    return null;
  }
  return (
    <SettingsGrid
      Icon={IconClockHour10}
      title="Meeting Duration"
      description="We will use this duration for your meetings unless otherwise specified."
      left={
        <Stack>
          <MinutesSelection label="Remote" initialValue={preferences.duration} onSelect={handleDurationSelect} />
          <MinutesSelection
            label="In-person"
            initialValue={preferences.inPersonDuration}
            onSelect={handleInPersonDurationSelect}
          />
        </Stack>
      }
    />
  );
};
