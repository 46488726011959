import { Box, Divider, Select, Space, Stack, Text } from '@mantine/core';

import { SideContent } from '@components/SideContent';
import { usePreferences, useUpdatePreferences } from '@features/preferences';

import { useCalendarsByAccounts } from '../api/getCalendars';

import { AccountDetails } from './AccountDetails';
import { ConnectCalendarButton } from './ConnectCalendarButton';
import { RevokedAccountDetails } from './RevokedAccountDetails';

export const CalendarSettingsPage = () => {
  const { data: accounts, isLoading: isLoadingAccounts } = useCalendarsByAccounts();
  const { data: preferences, isLoading: isLoadingPreferences } = usePreferences();

  const { mutate: updatePreferences, isPending: isUpdatingPreferences } = useUpdatePreferences();

  if (!preferences || !accounts || isLoadingAccounts || isLoadingPreferences) {
    return null;
  }

  const handleDefaultCalendarChange = (value: string | null) => {
    if (!value) {
      return;
    }

    const newDefaultCalendar = value;
    updatePreferences({ ...preferences, defaultAccountId: newDefaultCalendar });
  };

  const sortedAccounts = accounts.sort((a, b) => a.email.localeCompare(b.email));
  return (
    <>
      <SideContent
        sideContent={
          <Text c="dimmed">Connect all your calendars so that Blockit has a full view of your schedule.</Text>
        }
      >
        <Stack maw={{ base: '100%', xl: 500 }}>
          <Select
            label="Default calendar"
            data={accounts.map((account) => account.email)}
            value={preferences.defaultAccountId}
            onChange={handleDefaultCalendarChange}
            disabled={isUpdatingPreferences}
            allowDeselect={false}
          />
          {sortedAccounts.map((account) => (
            <Box key={account.email} pb="sm">
              {account.state === 'active' && <AccountDetails account={account} />}
              {account.state === 'refresh_token_failed' && <RevokedAccountDetails account={account} />}
              <Space h="md" />
              <Divider />
            </Box>
          ))}
          <Box>
            <ConnectCalendarButton />
          </Box>
        </Stack>
      </SideContent>
    </>
  );
};
