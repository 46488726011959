/* eslint-disable max-len */
import { FC } from 'react';

import { Box, Button, Stack, Text } from '@mantine/core';
import { IconPlus } from '@tabler/icons-react';

import { useFirebaseAuthState, useGoogleOAuthPopup } from '@features/auth';
import { useMicrosoftOAuthPopup } from '@features/auth/hooks/useMicrosoftOAuthPopup';

export const ConnectCalendarButton: FC = () => {
  const authState = useFirebaseAuthState();
  const { openPopup, error } = useGoogleOAuthPopup();
  const { openPopup: openMicrosoftPopup, error: microsoftError } = useMicrosoftOAuthPopup();

  const handleGoogleLogin = () => {
    if (!authState.user) {
      throw new Error('User is not authenticated');
    }
    openPopup();
  };
  const handleMicrosoftLogin = () => {
    if (!authState.user) {
      throw new Error('User is not authenticated');
    }
    openMicrosoftPopup();
  };
  return (
    <Stack>
      <Button
        justify="center"
        fullWidth
        onClick={handleGoogleLogin}
        variant="outline"
        leftSection={<IconPlus size={18} />}
      >
        Add Google calendar
      </Button>
      {
        <Button
          justify="center"
          fullWidth
          onClick={handleMicrosoftLogin}
          variant="outline"
          leftSection={<IconPlus size={18} />}
        >
          Add Outlook calendar
        </Button>
      }
      {error && (
        <Box mt="sm">
          {error && (
            <Text c="red" size="xs">
              {/* TODO: Clean up error message from server to either return user facing string or a type to map to messages on the frontend */}
              {error.includes('Blockit requires calendar permission.')
                ? 'Blockit requires calendar permission. Make sure you check all permission boxes when connecting your calendar.'
                : error ?? microsoftError}
            </Text>
          )}
        </Box>
      )}
    </Stack>
  );
};
