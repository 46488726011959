import { useEffect, useState } from 'react';

import { Anchor, Box, Group, Stack, Text } from '@mantine/core';
import { TimeInput } from '@mantine/dates';
import { IconX } from '@tabler/icons-react';
import { DateTime } from 'luxon';

import { DayOfWeek } from '@/types';
import { TooltipActionIcon } from '@components/TooltipActionIcon';
import { DAY_OF_WEEK_LABELS } from '@constants/index';

import { usePreferences } from '../api/getPreferences';
import { useWorkingHours } from '../api/getWorkingHours';
import { useUpdatePreferences } from '../api/updatePreferences';
import { useUpdateWorkingHours } from '../api/updateWorkingHours';
import { WorkingHourSettings } from '../types';
import { constrainAvailabilityToWorkingHours } from '../utils/constrainAvailabilityToWorkingHours';

import { CopyWorkingHoursDropdown } from './CopyWorkingHoursDropdown';

type WorkingHoursErrors = {
  [key in DayOfWeek]?: string | null;
};

export const WorkingHoursForm = () => {
  const { data: initialWorkingHours } = useWorkingHours();
  const { data: preferences } = usePreferences();

  const { mutate: updatePreferences } = useUpdatePreferences();

  const { mutate: updateWorkingHours } = useUpdateWorkingHours();

  const [workingHours, setWorkingHours] = useState<WorkingHourSettings | undefined>();
  const [errors, setErrors] = useState<WorkingHoursErrors>({});

  useEffect(() => {
    // We only update working hours if they are not already set to only update the working hours
    // the first time that the form is loaded
    if (!initialWorkingHours || workingHours) return;

    setWorkingHours(initialWorkingHours);
  }, [initialWorkingHours, workingHours]);

  const updateWorkingHoursAndAvailability = (newWorkingHours: WorkingHourSettings) => {
    if (preferences && preferences.availability) {
      updatePreferences({
        ...preferences,
        availability: constrainAvailabilityToWorkingHours(preferences.availability, newWorkingHours),
      });
    }
    updateWorkingHours(newWorkingHours);
  };

  const handleWorkingHourChange = (day: DayOfWeek, start: string, end: string) => {
    const newWorkingHours = {
      ...workingHours,
      [day]: [start, end],
    };

    // We update the working hours in the state regardless of if they are valid
    // this way the form will show the current values and we can render the errors
    setWorkingHours(newWorkingHours);

    const newErrors: WorkingHoursErrors = {};

    const isValidWorkingHours = Object.entries(newWorkingHours).every(([, dayWorkingHours]) => {
      if (dayWorkingHours.length === 0) return true;

      const [start, end] = dayWorkingHours;

      if (!start || !end) return false;

      const parsedStart = DateTime.fromFormat(start, 'HH:mm');
      const parsedEnd = DateTime.fromFormat(end, 'HH:mm');

      if (!parsedStart.isValid || !parsedEnd.isValid) return false;
      if (parsedStart >= parsedEnd) {
        newErrors[day as DayOfWeek] = 'Start time must be before end time';
        return false;
      }
      return true;
    });

    setErrors(newErrors);

    if (isValidWorkingHours) {
      // We only update the working hours in the db if they are valid
      updateWorkingHoursAndAvailability(newWorkingHours);
    }
  };

  const setDefaultWorkingHours = (day: DayOfWeek) => {
    const newWorkingHours = {
      ...workingHours,
      [day]: ['09:00', '17:00'],
    };
    setWorkingHours(newWorkingHours);
    updateWorkingHours(newWorkingHours);
  };

  const removeWorkingHours = (day: DayOfWeek) => {
    const newWorkingHours = {
      ...workingHours,
      [day]: [],
    };
    setWorkingHours(newWorkingHours);
    updateWorkingHoursAndAvailability(newWorkingHours);
  };

  const handleCopyWorkingHours = (currentDay: DayOfWeek, daysToCopy: DayOfWeek[]) => {
    if (!workingHours) {
      return;
    }
    const workingHourToCopy = workingHours[currentDay];
    const newWorkingHours = { ...workingHours };
    daysToCopy.forEach((day) => (newWorkingHours[day] = workingHourToCopy));
    setWorkingHours(newWorkingHours);
    updateWorkingHoursAndAvailability(newWorkingHours);
  };

  if (!workingHours) return null;

  return (
    <Stack gap="sm">
      {Object.values(DayOfWeek).map((day) => {
        const workHours = workingHours[day];
        if (!workHours || workHours?.length === 0)
          return (
            <Box key={day}>
              <Text size="md" fw={600} w={100}>
                {DAY_OF_WEEK_LABELS[day]}
              </Text>
              <Anchor component="button" size="xs" onClick={() => setDefaultWorkingHours(day)}>
                + Add working hours
              </Anchor>
            </Box>
          );

        const [start, end] = workHours;

        return (
          <Box key={day}>
            <Text size="md" fw={600}>
              {DAY_OF_WEEK_LABELS[day]}
            </Text>
            <Group gap="xs">
              <TimeInput
                w={98}
                size="sm"
                value={start}
                onChange={(e) => handleWorkingHourChange(day, e.target.value, end)}
              />
              <Text>-</Text>
              <TimeInput
                w={98}
                size="sm"
                value={end}
                onChange={(e) => handleWorkingHourChange(day, start, e.target.value)}
              />
              <TooltipActionIcon
                onClick={() => removeWorkingHours(day)}
                size="sm"
                icon={<IconX />}
                title="Remove working hours"
              />
              <CopyWorkingHoursDropdown
                currentDayIndex={day}
                onSubmit={(daysToCopy) => handleCopyWorkingHours(day, daysToCopy)}
              />
            </Group>
            <Text size="sm" c="red">
              {errors[day]}
            </Text>
          </Box>
        );
      })}
    </Stack>
  );
};
