import { Stack, Text } from '@mantine/core';
import { notifications } from '@mantine/notifications';
import { IconBusStop } from '@tabler/icons-react';

import { MINUTES_IN_HOUR } from '@constants/index';
import { AnalyticsEventName, track } from '@features/analytics';

import { usePreferences } from '../api/getPreferences';
import { useUpdatePreferences } from '../api/updatePreferences';

import { MinutesSelection } from './MinutesSelection';
import { SettingsSectionTitle } from './SettingsSectionTitle';

export const TravelTime = () => {
  const { data: preferences } = usePreferences();

  const { mutate: updatePreferences } = useUpdatePreferences({
    onSuccess: () => {
      track({ type: AnalyticsEventName.PreferencesUpdated, data: { preference: 'travelTimeBufferInMinutes' } });
      notifications.show({ message: 'Travel time buffers updated', color: 'green' });
    },
  });

  const handleTravelTimeSelect = (value: number) => {
    if (!preferences || !value) return;
    updatePreferences({ ...preferences, travelTimeBufferInMinutes: value });
  };

  const handleFlightTravelTimeSelect = (value: number) => {
    if (!preferences || !value) return;
    updatePreferences({ ...preferences, flightTravelTimeBufferInMinutes: value });
  };

  if (!preferences) {
    return null;
  }
  return (
    <Stack>
      <SettingsSectionTitle
        Icon={IconBusStop}
        title="Travel time"
        description="Let Blockit know how much time you want to allocate for travel."
      />
      <span>
        <Text component="span">I want to allocate</Text>
        <MinutesSelection
          initialValue={preferences.travelTimeBufferInMinutes}
          onSelect={handleTravelTimeSelect}
          range={[0, 60]}
          isInline
        />
        <Text component="span">before and after in-person meetings for travel.</Text>
      </span>
      <span>
        <Text component="span">I want to allocate</Text>
        <MinutesSelection
          initialValue={preferences.flightTravelTimeBufferInMinutes}
          onSelect={handleFlightTravelTimeSelect}
          range={[0, 4 * MINUTES_IN_HOUR]}
          isInline
        />
        <Text component="span">before and after flights for travel.</Text>
      </span>
    </Stack>
  );
};
