import { Loader } from '@mantine/core';
import { Navigate, Outlet, useLocation } from 'react-router-dom';

import { AppShell } from '@components/AppShell';
import { PageContainer } from '@components/PageContainer';
import { useLogPageView } from '@features/analytics';
import { signOut, useFirebaseAuthState } from '@features/auth';
import { BetaPage } from '@features/auth/components/BetaPage';
import { CalendarSettingsPage } from '@features/calendar-selection';
import { PlacesPage } from '@features/location/components/PlacesPage';
import { AvailabilityPage, SettingsPage } from '@features/preferences';
import { useUser } from '@features/users';
import { getActiveLink } from '@utils/getActiveLink';

import { ROUTES_TO_ROUTE_DETAILS } from './constants';
import { PRIVATE_ROUTES, PUBLIC_ROUTES } from './enums';
import { internalRoutes } from './internal';

// eslint-disable-next-line react-refresh/only-export-components
const App = () => {
  const authState = useFirebaseAuthState();

  // For now we handle page view analytics here for all private pages
  // We may want to consider handling on individual pages as analytics needs
  // per page become more complex
  const { pathname } = useLocation();
  const activeLink = getActiveLink(Object.values(PRIVATE_ROUTES), pathname);
  const routeDetails = activeLink ? ROUTES_TO_ROUTE_DETAILS[activeLink] : null;
  const { data: user, isLoading: userIsLoading } = useUser();
  useLogPageView(routeDetails?.title);
  // Consider not exposing routes if user is not logged in
  // TODO: Redirect to login page once that is built

  if (authState.loading || userIsLoading) {
    return (
      <PageContainer>
        <Loader size="xl" />
      </PageContainer>
    );
  }
  if (!authState.signedIn) return <Navigate to={PUBLIC_ROUTES.LOGIN} />;

  if (!user) {
    return (
      <PageContainer>
        <Loader size="xl" />
      </PageContainer>
    );
  }
  if (user.suspendedAt) {
    signOut();
  }
  return (
    <AppShell>
      <Outlet />
    </AppShell>
  );
};

export const protectedRoutes = [
  {
    path: '/',
    element: <App />,
    children: [
      { path: '/', element: <Navigate to={PRIVATE_ROUTES.CALENDARS} /> },
      { path: PRIVATE_ROUTES.CALENDARS, element: <CalendarSettingsPage /> },
      { path: PRIVATE_ROUTES.AVAILABILITY, element: <AvailabilityPage /> },
      { path: PRIVATE_ROUTES.SETTINGS, element: <SettingsPage /> },
      { path: PRIVATE_ROUTES.BETA, element: <BetaPage /> },
      { path: PRIVATE_ROUTES.PLACES, element: <PlacesPage /> },
      ...internalRoutes,
      { path: '*', element: <Navigate to={PRIVATE_ROUTES.CALENDARS} /> },
    ],
  },
];
