export enum PRIVATE_ROUTES {
  CALENDARS = '/calendars',
  PLACES = '/places',
  AVAILABILITY = '/availability',
  SETTINGS = '/settings',
  BETA = '/beta',
}

export enum INTERNAL_ROUTES {
  INTERNAL = '/internal',
}

export enum PUBLIC_ROUTES {
  GOOGLE_OAUTH = '/googleoauth',
  SLACK_OAUTH = '/slackoauth',
  LOGIN = '/login',
  MICROSOFT_OAUTH = '/microsoftoauth',
}
