// Matches WorkingHourSettings defined in backend
// eslint-disable-next-line max-len
//https://github.com/Blockitons/blockit-server-v2/blob/6ddcec1c2dbcac8f791820a73dc9d0378a60a77e/src/services/workingHourService.ts

import { DayOfWeek } from '@/types';

// prettier-ignore
export const Hour =  ['00', '01', '02', '03', '04', '05', '06', '07', '08', '09', 
                      '10', '11', '12', '13', '14', '15', '16', '17', '18', '19', 
                      '20', '21', '22', '23'] as const;
export type Hour = (typeof Hour)[number];

// prettier-ignore
export const Minute = ['00' , '01' , '02' , '03' , '04' , '05' , '06' , '07' , '08' , '09', 
                       '10' , '11' , '12' , '13' , '14' , '15' , '16' , '17' , '18' , '19', 
                       '20' , '21' , '22' , '23' , '24' , '25' , '26' , '27' , '28' , '29', 
                       '30' , '31' , '32' , '33' , '34' , '35' , '36' , '37' , '38' , '39', 
                       '40' , '41' , '42' , '43' , '44' , '45' , '46' , '47' , '48' , '49', 
                       '50' , '51' , '52' , '53' , '54' , '55' , '56' , '57' , '58' , '59'] as const;
export type Minute = (typeof Minute)[number];

export type TimeOfDay = `${Hour}:${Minute}`;

export type WorkingHour = [TimeOfDay, TimeOfDay]; // Tuple with two TimeOfDay elements representing start and end times

export type WorkingHourSettings = {
  [day in DayOfWeek]?: WorkingHour | [];
};

export enum LinkType {
  GoogleMeet = 'GoogleMeet',
  CustomLink = 'CustomLink',
}

export enum AvailabilityBlockType {
  AVAILABLE = 'available',
  NOT_PREFERRED = 'not_preferred',
}

export interface AvailabilityBlock {
  start: string;
  end: string;
  type: AvailabilityBlockType;
}

export type Availability = {
  [k in DayOfWeek]: AvailabilityBlock[];
};
export interface ConsecutiveMeetingMinutesBuffer {
  durationInMinutes: number;
  maxMeetingMinutes: number;
}

export interface PreferenceSettings {
  id?: string;
  duration: number;
  inPersonDuration: number;
  preferredStartingTimes: string[] | null;
  linkType: LinkType;
  link?: string;
  linkName?: string;
  defaultAccountId?: string;
  availability?: Availability;
  timeZone?: string;
  travelTimeBufferInMinutes: number;
  flightTravelTimeBufferInMinutes: number;
  consecutiveMeetingBuffer?: ConsecutiveMeetingMinutesBuffer;
  everyMeetingBufferInMinutes?: number;
  maxMeetingMinutesPerDay?: number;
  nudgeEnabled: boolean;
  numDaysToNudge: number;
  maxTimesToNudge: number;
}
