import { useMutation, useQueryClient } from '@tanstack/react-query';

import { DayOfWeek } from '@/types';
import { axios } from '@lib/axios';

import { LocationType, PointOfInterest } from '../types';

interface UpsertPointOfInterestParams {
  locationType: LocationType;
  googlePlaceId: string;
  locationDescription: string;
  anchorAvailability: DayOfWeek[];
  name: string;
  id?: string;
}

const upsertPointOfInterest = async ({
  locationType,
  googlePlaceId,
  locationDescription,
  anchorAvailability,
  name,
  id,
}: UpsertPointOfInterestParams): Promise<PointOfInterest> => {
  const { data } = await axios.put('/location/points-of-interest', {
    locationType,
    googlePlaceId,
    locationDescription,
    anchorAvailability,
    id,
    name,
  });

  return data;
};

export const useUpsertPointOfInterest = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: upsertPointOfInterest,
    onSuccess: () => {
      queryClient.refetchQueries({
        queryKey: ['pointsOfInterest'],
      });
    },
  });
};
