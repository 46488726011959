import { Button, Chip, Group, Select, Stack, Text, TextInput } from '@mantine/core';
import { useForm } from '@mantine/form';

import { DayOfWeek, GooglePlaceInfo } from '@/types';
import { LocationAutocomplete } from '@components/Form/LocationAutocomplete';
import { DAY_OF_WEEK_LABELS_ABBREVIATED } from '@constants/index';

import { usePointsOfInterest } from '../api/getPointsOfInterest';
import { LocationType, PointOfInterest } from '../types';

interface PointOfInterestFormProps {
  initialPointOfInterest: PointOfInterest | null;
  onSubmit: (values: {
    location: GooglePlaceInfo | null;
    nickname: string;
    locationType: LocationType;
    anchorAvailability: DayOfWeek[];
  }) => void;
}

export const PointOfInterestForm = ({ initialPointOfInterest, onSubmit }: PointOfInterestFormProps) => {
  const { data: pointsOfInterest } = usePointsOfInterest();
  const form = useForm({
    initialValues: {
      location: initialPointOfInterest?.location.googlePlaceId
        ? {
            placeId: initialPointOfInterest?.location.googlePlaceId,
            description: initialPointOfInterest?.location.description,
          }
        : null,
      nickname: initialPointOfInterest?.name ?? '',
      locationType: initialPointOfInterest?.locationType ?? LocationType.COFFEE,
      anchorAvailability: initialPointOfInterest?.anchorAvailability ?? [],
    },
    validate: {
      location: (value) => (value ? null : 'Address is required'),
      nickname: (value) => (value ? null : 'Nickname is required'),
      locationType: (value) => (value ? null : 'Location type is required'),
    },
  });

  if (!pointsOfInterest) {
    return null;
  }
  const existingAnchorAvailabilities = pointsOfInterest
    .filter((pointOfInterest) => pointOfInterest.id !== initialPointOfInterest?.id)
    .flatMap((pointOfInterest) => pointOfInterest.anchorAvailability);

  return (
    <form onSubmit={form.onSubmit((values) => onSubmit(values))}>
      <Stack>
        <Select
          label="Type"
          required
          allowDeselect={false}
          data={[
            { value: LocationType.HOME, label: 'Home' },
            { value: LocationType.OFFICE, label: 'Office' },
            { value: LocationType.COFFEE, label: 'Coffee' },
          ]}
          {...form.getInputProps('locationType')}
        />
        <LocationAutocomplete
          initialValue={form.values.location?.description || ''}
          setLocation={(location) => form.setFieldValue('location', location)}
          label="Establishment or Address"
          required
          {...form.getInputProps('location')}
        />
        <TextInput label="Nickname" placeholder="Enter nickname" {...form.getInputProps('nickname')} required />
        <Stack gap="xs">
          <Text size="sm" fw={500}>
            When
          </Text>
          <Text size="xs" c="dimmed">
            When you&apos;ll be at this location.
          </Text>
        </Stack>
        <Chip.Group {...form.getInputProps('anchorAvailability')} multiple>
          <Group gap="xs">
            {Object.entries(DAY_OF_WEEK_LABELS_ABBREVIATED).map(([key, label]) => (
              <Chip
                key={key}
                value={key}
                size="xs"
                disabled={existingAnchorAvailabilities.some((availability) => availability === key)}
              >
                {label}
              </Chip>
            ))}
          </Group>
        </Chip.Group>
        <Button type="submit">Submit</Button>
      </Stack>
    </form>
  );
};
