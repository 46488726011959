import { useCallback, useEffect, useState } from 'react';

import { Loader } from '@mantine/core';
import { useNavigate } from 'react-router-dom';

import { PageContainer } from '@components/PageContainer';
import { PRIVATE_ROUTES } from '@routes/enums';

import { useFirebaseAuthState } from '..';
import { useSlackConnectAccount } from '../api/connectSlackAccount';

export const SlackOAuthPage = () => {
  const authState = useFirebaseAuthState();
  const [done, setDone] = useState(false);
  const navigate = useNavigate();
  const { mutateAsync: connectSlackAccount } = useSlackConnectAccount();

  const getToken = useCallback(async () => {
    if (!authState.uid) {
      return;
    }
    // Create a URL object to parse the URL
    const url = new URL(window.location.href);

    const code = url.searchParams.get('code');
    if (!code) {
      throw new Error('invalid `code` or `redirect_uri` parameter');
    }

    await connectSlackAccount({ userId: authState.uid, code });
    setDone(true);

    // redirect back to the beta page if successful after 1 seconds
    setTimeout(() => {
      navigate(PRIVATE_ROUTES.BETA);
    }, 1000);
  }, [authState.uid, connectSlackAccount, navigate]);

  useEffect(() => {
    getToken();
  }, [getToken]);

  if (!authState.loading && !authState.uid) {
    return <PageContainer>Not logged in.</PageContainer>;
  } else if (done) {
    return <PageContainer>Success! Redirecging back to settings...</PageContainer>;
  } else {
    return (
      <PageContainer>
        <Loader size={30} />
      </PageContainer>
    );
  }
};
