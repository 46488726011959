import { FC, ReactNode } from 'react';

import { Box, Drawer, Group, Paper, Space, useMantineTheme } from '@mantine/core';
import { useDisclosure, useMediaQuery } from '@mantine/hooks';

import { SignOutButton } from '@features/auth/components/SignOutButton';

import classes from './AppShell.module.css';
import { MobileHeader } from './MobileHeader';
import { NavLinks } from './NavLinks';
import { PageContainer } from './PageContainer';
import { SideNav } from './SideNav';

interface NavBarProps {
  children: ReactNode;
}

/**
 * Renders a responsive app shell with the navbar and the page content.
 */
export const AppShell: FC<NavBarProps> = ({ children }) => {
  const theme = useMantineTheme();
  const isExtraSmallScreen = useMediaQuery(`(max-width: ${theme.breakpoints.xs})`);

  const [opened, { toggle }] = useDisclosure();

  return (
    <PageContainer>
      <Group
        h={{ base: '100%', md: '100vh' }}
        w="100%"
        justify="center"
        p={{ base: '', xs: 'xl' }}
        maw="2500"
        align="stretch"
        grow
        gap="md"
        wrap="nowrap"
      >
        <SideNav />
        <Paper
          radius={isExtraSmallScreen ? '' : 'lg'}
          className={classes.mainContainer}
          maw="100%"
          p={{ base: '', lg: 'md' }}
          h="100%"
        >
          <Box component="header" hiddenFrom="lg" px="lg" pt="lg" className={classes.header}>
            <MobileHeader opened={opened} toggle={toggle} />
          </Box>
          <Drawer opened={opened} onClose={toggle} withCloseButton={false} position="top" h="100%" size="xs">
            <MobileHeader opened={opened} toggle={toggle} />
            <NavLinks onNavigate={toggle} />
            <Space h="md" />
            <SignOutButton />
          </Drawer>
          <Box px="md" pt={{ base: '', lg: 'md' }} pb="md" h="100%">
            {children}
          </Box>
        </Paper>
      </Group>
    </PageContainer>
  );
};
