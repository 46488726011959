import { GoogleOAuthPage, SlackOAuthPage } from '@features/auth';
import { MicrosoftOAuthPage } from '@features/auth/components/MicrosoftOAuthPage';
import { OnboardingPage } from '@features/onboarding';

import { PUBLIC_ROUTES } from './enums';

export const publicRoutes = [
  {
    path: PUBLIC_ROUTES.LOGIN,
    element: <OnboardingPage />,
  },
  {
    path: PUBLIC_ROUTES.GOOGLE_OAUTH,
    element: <GoogleOAuthPage />,
  },
  {
    path: PUBLIC_ROUTES.SLACK_OAUTH,
    element: <SlackOAuthPage />,
  },
  {
    path: PUBLIC_ROUTES.MICROSOFT_OAUTH,
    element: <MicrosoftOAuthPage />,
  },
];
