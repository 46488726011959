import { Box, Stack, Text, Title } from '@mantine/core';

import { SideContent } from '@components/SideContent';

import { AvailabilityCalendar } from './AvailabilityCalendar';
import { WorkingHoursForm } from './WorkingHoursForm';

export const AvailabilityPage = () => {
  return (
    <SideContent
      sideContentSize="300"
      sideContent={
        <Stack>
          <Box>
            <Title order={4}>Working hours</Title>
            <Text c="dimmed" size="sm">
              We will never propose times outside of your working hours.
            </Text>
          </Box>
          <WorkingHoursForm />
        </Stack>
      }
    >
      <AvailabilityCalendar />
    </SideContent>
  );
};
