import { Divider, Stack, Text } from '@mantine/core';

import { SideContent } from '@components/SideContent';

import { usePreferences } from '../api/getPreferences';

import { DefaultTimeZone } from './DefaultTimeZone';
import { MaxMeetingMinutes } from './MaxMeetingMinutes';
import { MeetingBuffers } from './MeetingBuffers';
import { MeetingDuration } from './MeetingDuration';
import { MeetingNudge } from './MeetingNudge';
import { TravelTime } from './TravelTime';
import { VirtualMeetings } from './VirtualMeetings';

export const SettingsPage = () => {
  const { isLoading: isLoadingPreferences, data: preferences } = usePreferences();

  if (isLoadingPreferences || !preferences) {
    return null;
  }

  return (
    <SideContent
      sideContent={
        <Text c="dimmed">
          Add your preferences. We will factor in your selections when we schedule meetings for you.
        </Text>
      }
    >
      <Stack gap="xl">
        <VirtualMeetings />
        <Divider />

        <MeetingNudge initialPreferences={preferences} />
        <Divider />
        <TravelTime />
        <Divider />
        <MeetingBuffers initialPreferences={preferences} />
        <Divider />
        <MaxMeetingMinutes initialPreferences={preferences} />
        <Divider />
        <MeetingDuration />
        <Divider />
        <DefaultTimeZone />
      </Stack>
    </SideContent>
  );
};
