import React, { forwardRef } from 'react';

import { ActionIcon, ActionIconProps, Tooltip, createPolymorphicComponent } from '@mantine/core';

import classes from './TooltipActionIcon.module.css';

interface TooltipIconProps extends ActionIconProps {
  title?: string;
  icon: React.ReactNode;
}
const TooltipActionIconComponent = forwardRef<HTMLButtonElement, TooltipIconProps>((props, ref) => {
  const { title, icon, ...otherProps } = props;

  const actionIcon = (
    <ActionIcon {...otherProps} variant="subtle" className={classes.actionIcon} ref={ref}>
      {icon}
    </ActionIcon>
  );

  return title ? (
    <Tooltip
      label={title}
      transitionProps={{ transition: 'fade', duration: 300 }}
      position="right"
      arrowSize={6}
      withArrow
    >
      {actionIcon}
    </Tooltip>
  ) : (
    actionIcon
  );
});

export const TooltipActionIcon = createPolymorphicComponent<'button', TooltipIconProps>(TooltipActionIconComponent);
TooltipActionIconComponent.displayName = 'TooltipActionIcon';
