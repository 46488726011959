import { notifications } from '@mantine/notifications';
import { useMutation, useQueryClient } from '@tanstack/react-query';

import { axios } from '@lib/axios';

import { WorkingHourSettings } from '../types';

const updateWorkingHours = async (workingHoursToUpdate: WorkingHourSettings): Promise<WorkingHourSettings> => {
  const { data } = await axios.post('/user/workingHours', {
    workingHours: workingHoursToUpdate,
  });
  return data.workingHours;
};

export const useUpdateWorkingHours = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: updateWorkingHours,
    onSuccess: (workingHours) => {
      queryClient.setQueryData(['workingHours'], workingHours);
      notifications.show({ message: 'Working hours updated', color: 'green', autoClose: 3000 });
    },
  });
};
