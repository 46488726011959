import amplitude from 'amplitude-js';

import { AnalyticsEvent, IAnalyticsEventSchema } from '../types';

import { checkIsTrackingEnabled } from './checkIsTrackingEnabled';

export const track = <T extends keyof IAnalyticsEventSchema>(event: AnalyticsEvent<T>) => {
  if (checkIsTrackingEnabled()) {
    amplitude.getInstance().logEvent(event.type.valueOf(), { ...event.data, pathname: window.location.pathname });
  }
};
