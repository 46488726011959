import { FC, useCallback, useEffect, useMemo, useRef, useState } from 'react';

import type { DateSpanApi, EventInput } from '@fullcalendar/core';
import interactionPlugin from '@fullcalendar/interaction';
import FullCalendar from '@fullcalendar/react';
import timeGridPlugin from '@fullcalendar/timegrid';
import { ActionIcon, Box, Group, ScrollArea, Text, alpha, useMantineTheme } from '@mantine/core';
import { useDisclosure } from '@mantine/hooks';
import { notifications } from '@mantine/notifications';
import { IconStar, IconX } from '@tabler/icons-react';
import { DateTime } from 'luxon';
import { v4 as uuidv4 } from 'uuid';

import { DayOfWeek } from '@/types';
import { InfoTooltip } from '@components/InfoTooltip';

import { usePreferences } from '../api/getPreferences';
import { useWorkingHours } from '../api/getWorkingHours';
import { useUpdatePreferences } from '../api/updatePreferences';
import { AvailabilityBlockType } from '../types';
import {
  AvaibilityEvent,
  areAvailabilitiesEqual,
  checkIfEventsOverlapAndShouldNotMerge,
  checkIfEventsShouldMerge,
  convertAvailabilityToEvents,
  convertDateInputToDateTime,
  convertEventsToAvailability,
  convertWorkingHoursToBusinessHours,
  eventMetadataFromType,
  isEventOnOneDay,
  isEventOutsideWorkingHours,
} from '../utils/fullCalendar';

// In most cases, we want to use css modules for css to avoid css leaking
// in unexpected places, however we have to use regular css here to override
// classes in the fullcalendar library
import './AvailabilityCalendar.css';

export const AvailabilityCalendar: FC = () => {
  const [events, setEvents] = useState<AvaibilityEvent[] | null>(null);
  const [eventType] = useState<AvailabilityBlockType>(AvailabilityBlockType.AVAILABLE);
  const theme = useMantineTheme();
  const { data: preferences } = usePreferences();
  const { data: workingHours } = useWorkingHours();
  const availability = preferences?.availability;
  const calendarRef = useRef<FullCalendar | null>(null);
  const { mutate: updatePreferences, isPending: isPreferencesUpdating } = useUpdatePreferences();

  const [isGrabbing, { close: stopGrabbing, open: startGrabbing }] = useDisclosure();
  const grabbingClass = isGrabbing ? 'grabbing-cursor' : '';

  const [isResizing, { close: stopResizing, open: startResizing }] = useDisclosure();
  const resizingClass = isResizing ? 'row-resize-cursor' : '';

  const showWeekends =
    workingHours?.[DayOfWeek.Saturday]?.length === 2 || workingHours?.[DayOfWeek.Sunday]?.length === 2;

  const eventColorsFromType = useCallback(
    (type: AvailabilityBlockType) => {
      const baseColor = type === AvailabilityBlockType.AVAILABLE ? theme.colors.blue[6] : theme.colors.yellow[6];
      return {
        textColor: baseColor,
        backgroundColor: alpha(baseColor, 0.1),
        borderColor: baseColor,
      };
    },
    [theme],
  );

  const defaultEventDetails = useMemo(() => {
    const { title, extendedProps } = eventMetadataFromType(eventType);

    return {
      title,
      extendedProps,
      overlap: false,
    };
  }, [eventType]);

  useEffect(() => {
    if (availability) {
      setEvents(
        convertAvailabilityToEvents(availability).map((event) => ({
          ...defaultEventDetails,
          ...eventColorsFromType(event.extendedProps?.type),
          ...event,
        })),
      );
    }
  }, [eventColorsFromType, availability, defaultEventDetails]);

  // Callback used to tell the calendar to change its size when the container resizes
  const resizeObserverCallback = useCallback((node: HTMLElement | null) => {
    if (!node) return;
    const resizeObserver = new ResizeObserver(() => {
      const calendar = calendarRef.current;
      if (!calendar) return;
      calendar.getApi().updateSize();
    });

    // Observe the div
    if (node) {
      resizeObserver.observe(node);
    }

    // Cleanup function
    return () => {
      if (node) {
        resizeObserver.unobserve(node);
      }
    };
  }, []);

  const handleSlotSelect = (info: { start: Date; end: Date }) => {
    if (!calendarRef.current) return;
    calendarRef.current.getApi().unselect();

    if (!isEventOnOneDay(info)) {
      notifications.show({
        message: 'Availability blocks cannot span multiple days.',
        title: 'Error',
        color: theme.colors.red[6],
      });
      return;
    }

    if (workingHours && isEventOutsideWorkingHours(info, workingHours)) {
      notifications.show({
        message: 'Availability blocks cannot be outside working hours.',
        title: 'Error',
        color: theme.colors.red[6],
      });
      return;
    }

    if (!events || !preferences) return;

    const overlappingEventViolations = events.some((event) => {
      return checkIfEventsOverlapAndShouldNotMerge(event, {
        title: defaultEventDetails.title,
        start: info.start,
        end: info.end,
      });
    });

    // If there are overlapping events that are not of the same type
    // as the event we are adding, we don't want to add a new event
    if (overlappingEventViolations) {
      notifications.show({
        title: 'Error',
        message: 'Blocks of different types cannot overlap.',
        color: theme.colors.red[6],
      });
      return;
    }

    const eventsToMerge = events.filter((event) => {
      return checkIfEventsShouldMerge(event, { title: defaultEventDetails.title, start: info.start, end: info.end });
    });

    let newEvents = [...events];
    if (eventsToMerge.length >= 1) {
      // Getting the start and end time of the new merged event
      const start = DateTime.min(
        ...eventsToMerge.map((event) => convertDateInputToDateTime(event.start as Date)),
        convertDateInputToDateTime(info.start),
      ).toJSDate();
      const end = DateTime.max(
        ...eventsToMerge.map((event) => convertDateInputToDateTime(event.end as Date)),
        convertDateInputToDateTime(info.end),
      ).toJSDate();

      newEvents = newEvents.filter((event) => {
        return !eventsToMerge.includes(event);
      });

      newEvents.push({
        ...defaultEventDetails,
        ...eventColorsFromType(eventType),
        end,
        start,
        id: uuidv4(),
      });
    } else {
      newEvents.push({
        ...defaultEventDetails,
        ...eventColorsFromType(eventType),
        start: info.start,
        end: info.end,
        id: uuidv4(),
      });
    }
    updatePreferences({ ...preferences, availability: convertEventsToAvailability(newEvents) });
  };

  const handleEventDelete = (event: EventInput) => {
    event.remove();
  };

  const renderEventContent = (eventInfo: { timeText: string; event: AvaibilityEvent }) => {
    const textColor = eventInfo.event.extendedProps?.type
      ? eventColorsFromType(eventInfo.event.extendedProps.type).textColor
      : eventColorsFromType(eventType).textColor;
    return (
      <>
        <Group justify="space-between">
          <Group gap="2" align="center">
            <Box>
              <IconStar size="12" />
            </Box>
            <Text fw={700} size="xs">
              {eventInfo.event.title || eventMetadataFromType(eventType).title}
            </Text>
          </Group>
          <ActionIcon
            size="sm"
            onClick={() => handleEventDelete(eventInfo.event)}
            variant="transparent"
            color={textColor}
          >
            <IconX name="x" size={12} />
          </ActionIcon>
        </Group>
        <Text size="xs">{eventInfo.timeText}</Text>
      </>
    );
  };

  const handleAllowEvent = (span: DateSpanApi) => {
    if (!calendarRef.current || !workingHours) return false;
    const newEvent = { start: span.start, end: span.end };
    if (!isEventOnOneDay(newEvent)) {
      return false;
    }
    if (isEventOutsideWorkingHours(newEvent, workingHours)) {
      return false;
    }

    return true;
  };

  if (!availability || !workingHours || !events) {
    return null;
  }

  return (
    <Box>
      <Group gap="xs" justify="space-between" maw="1200">
        <Group gap="xs">
          <IconStar size="1rem" />
          <Group gap="1">
            <Text>Drag preferred blocks on the calendar.</Text>
            <InfoTooltip description="We will try to schedule your meetings during the preferred blocks." />
          </Group>
        </Group>
      </Group>
      <ScrollArea w="100%" maw="1200" h="100%" pb="xs" ref={resizeObserverCallback}>
        <Box miw={showWeekends ? 1100 : 800} className={`${grabbingClass} ${resizingClass}`}>
          <FullCalendar
            ref={calendarRef}
            longPressDelay={500}
            plugins={[timeGridPlugin, interactionPlugin]}
            initialView="timeGridWeek"
            headerToolbar={{ left: '', center: '', right: '' }}
            weekends={showWeekends}
            events={events}
            eventContent={renderEventContent}
            dayHeaderFormat={{ weekday: 'long' }}
            allDaySlot={false}
            slotDuration={'00:30:00'}
            slotLabelInterval={'01:00:00'}
            firstDay={1}
            slotLabelFormat={{ hour: 'numeric', meridiem: 'short' }}
            select={handleSlotSelect}
            selectable={!isPreferencesUpdating}
            editable={!isPreferencesUpdating}
            eventOverlap={false}
            scrollTime={'08:00:00'}
            eventMinHeight={1}
            dragScroll={true}
            eventAllow={handleAllowEvent}
            eventTextColor={eventColorsFromType(eventType).textColor}
            eventColor={eventColorsFromType(eventType).backgroundColor}
            selectMirror={true}
            eventTimeFormat={{ hour: 'numeric', minute: '2-digit' }}
            eventResizableFromStart={true}
            eventDragStart={() => startGrabbing()}
            eventDragStop={() => stopGrabbing()}
            eventResizeStart={() => startResizing()}
            eventResizeStop={() => stopResizing()}
            eventsSet={(events) =>
              !areAvailabilitiesEqual(convertEventsToAvailability(events), availability) &&
              updatePreferences({ ...preferences, availability: convertEventsToAvailability(events) })
            }
            businessHours={convertWorkingHoursToBusinessHours(workingHours)}
            dayHeaderContent={(arg) => {
              return (
                <Box my="md">
                  <Text>{arg.text}</Text>
                </Box>
              );
            }}
          />
        </Box>
      </ScrollArea>
    </Box>
  );
};
