import { FC } from 'react';

import { Box, Button, Card, Stack, Text, Title } from '@mantine/core';
import { Link } from 'react-router-dom';

import { VerifiedText } from '@components/VerifiedText';
import { PRIVATE_ROUTES } from '@routes/enums';

import { useCalendarsByAccounts } from '../api/getCalendars';

import { ConnectAnotherCard } from './ConnectAnotherCard';
import { ConnectCalendarButton } from './ConnectCalendarButton';

export const CalendarSelector: FC = () => {
  const { data: accounts } = useCalendarsByAccounts();

  const hasAccounts = accounts && accounts.length > 0;

  return (
    <Stack>
      <Card padding="xl" radius="lg">
        <Stack>
          <Title order={1} size="h2" ta="center">
            {hasAccounts ? 'Connected calendars' : 'Connect your calendar'}
          </Title>
          <Text c="dimmed">
            Connect all your calendars to ensure Blockit has a full view of your schedule, avoiding overlaps and
            conflicts.
          </Text>
          {accounts?.map((account) => <VerifiedText key={account.email} text={account.email} />)}
          {hasAccounts ? (
            <Button component={Link} to={PRIVATE_ROUTES.CALENDARS}>
              Continue to Blockit
            </Button>
          ) : (
            <Box>
              <ConnectCalendarButton />
            </Box>
          )}
        </Stack>
      </Card>
      {hasAccounts && <ConnectAnotherCard />}
    </Stack>
  );
};
