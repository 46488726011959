import { FC, useState } from 'react';

import { Button, Checkbox, Group, Popover, Stack, Text } from '@mantine/core';
import { useClickOutside, useDisclosure } from '@mantine/hooks';
import { IconSquares } from '@tabler/icons-react';

import { DayOfWeek } from '@/types';
import { TooltipActionIcon } from '@components/TooltipActionIcon';
import { DAY_OF_WEEK_LABELS } from '@constants/index';
import { flipObject } from '@utils/flipObject';

const LABEL_TO_DAY = flipObject(DAY_OF_WEEK_LABELS);

interface CopyWorkingHoursDropdownProps {
  currentDayIndex: DayOfWeek;
  onSubmit: (daysToCopy: DayOfWeek[]) => void;
}

export const CopyWorkingHoursDropdown: FC<CopyWorkingHoursDropdownProps> = ({ currentDayIndex, onSubmit }) => {
  const [opened, { close, toggle }] = useDisclosure(false);

  const [dropdown, setDropdown] = useState<HTMLDivElement | null>(null);
  const [control, setControl] = useState<HTMLButtonElement | null>(null);

  useClickOutside(() => close(), null, [control, dropdown]);

  // By default, we copy all the days after the current day until the weekend
  const [daysToCopy, setDaysToCopy] = useState<DayOfWeek[]>(
    Object.values(DayOfWeek)
      .filter((day) => parseInt(day) > parseInt(currentDayIndex) && parseInt(day) < 6)
      .concat([currentDayIndex]),
  );

  const handleSubmit = () => {
    onSubmit(daysToCopy);
    // clear the selections
    setDaysToCopy([currentDayIndex]);
    close();
  };

  const handleChange = (days: string[]) => {
    setDaysToCopy(days.map((day) => LABEL_TO_DAY[day] as DayOfWeek));
  };
  return (
    <Popover
      width={200}
      position="bottom"
      withArrow
      shadow="md"
      opened={opened}
      middlewares={{ flip: true, shift: true, inline: true }}
    >
      <Popover.Target>
        <TooltipActionIcon
          size="sm"
          icon={<IconSquares />}
          title="Copy to other days"
          onClick={toggle}
          ref={setControl}
        />
      </Popover.Target>
      <Popover.Dropdown ref={setDropdown}>
        <Checkbox.Group value={daysToCopy.map((day) => DAY_OF_WEEK_LABELS[day])} onChange={handleChange}>
          <Stack gap="xs">
            {Object.entries(DAY_OF_WEEK_LABELS).map(([dayIndex, dayLabel]) => (
              <Group key={dayLabel} justify="space-between">
                <Text>{dayLabel}</Text>
                <Checkbox disabled={dayIndex === currentDayIndex} value={dayLabel} />
              </Group>
            ))}
            <Button onClick={handleSubmit}>Copy times</Button>
          </Stack>
        </Checkbox.Group>
      </Popover.Dropdown>
    </Popover>
  );
};
