import { Loader, MantineProvider, createTheme } from '@mantine/core';
import { Notifications } from '@mantine/notifications';
import * as Sentry from '@sentry/react';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { APIProvider } from '@vis.gl/react-google-maps';
import amplitude from 'amplitude-js';
import { BrowserRouter } from 'react-router-dom';

import { CustomLoader } from '@components/CustomLoader';
import { ErrorFallback } from '@components/ErrorFallback';
import { AppRoutes } from '@routes/AppRoutes';

import '@mantine/core/styles.css';
import '@mantine/dates/styles.css';
import '@mantine/notifications/styles.css';

const queryClient = new QueryClient();

if (import.meta.env.VITE_AMPLITUDE_API_KEY) {
  amplitude.getInstance().init(import.meta.env.VITE_AMPLITUDE_API_KEY);
}

const App = () => {
  const theme = createTheme({
    colors: {
      blue: [
        '#6cc0fb',
        '#46acfa',
        '#31a0fa',
        '#249afb',
        '#1786e0',
        '#0077c9',
        '#006FBC',
        '#0066B2',
        '#00477d',
        '#002947',
      ],
      blockitBlue: [
        '#478DBC',
        '#3C81AF',
        '#3476A2',
        '#2D6B96',
        '#26628B',
        '#205A80',
        '#1B5277',
        '#1A4969',
        '#1A425D',
        '#183B52',
        '#173548',
        '#162F40',
        '#152A39',
      ],
    },
    components: {
      Loader: Loader.extend({
        defaultProps: {
          loaders: { ...Loader.defaultLoaders, custom: CustomLoader },
          type: 'custom',
        },
      }),
    },
  });
  return (
    <QueryClientProvider client={queryClient}>
      <MantineProvider theme={theme}>
        <Sentry.ErrorBoundary fallback={<ErrorFallback />}>
          <APIProvider apiKey={import.meta.env.VITE_GOOGLE_MAPS_API_KEY || ''} libraries={['marker']}>
            <BrowserRouter>
              <Notifications position="bottom-right" />
              <AppRoutes />
            </BrowserRouter>
          </APIProvider>
        </Sentry.ErrorBoundary>
      </MantineProvider>
    </QueryClientProvider>
  );
};

export default App;
