import { FC, ReactNode } from 'react';

import { Grid, Stack } from '@mantine/core';
import { IconProps } from '@tabler/icons-react';

import { SettingsSectionTitle } from './SettingsSectionTitle';

interface SettingsGridProps {
  left: ReactNode;
  right?: ReactNode;
  title: string;
  description: string;
  Icon: React.ComponentType<IconProps>;
}
export const SettingsGrid: FC<SettingsGridProps> = ({ left, right, title, Icon, description }) => {
  return (
    <Grid justify="space-between" overflow="hidden">
      <Grid.Col span={{ base: 12, md: 6 }} miw={300}>
        <Stack>
          <SettingsSectionTitle title={title} description={description} Icon={Icon} />
          {left}
        </Stack>
      </Grid.Col>
      <Grid.Col span={6}>{right}</Grid.Col>
    </Grid>
  );
};
