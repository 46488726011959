import { useEffect, useState } from 'react';

import { Button, Select, Stack, TextInput } from '@mantine/core';
import { useForm, zodResolver } from '@mantine/form';
import { notifications } from '@mantine/notifications';
import { IconDeviceDesktop } from '@tabler/icons-react';
import { z } from 'zod';

import { AnalyticsEventName, track } from '@features/analytics';
import { flipObject } from '@utils/flipObject';

import { usePreferences } from '../api/getPreferences';
import { useUpdatePreferences } from '../api/updatePreferences';
import { LinkType } from '../types';

import { SettingsGrid } from './SettingsGrid';

const DISPLAY_NAME_TO_LINK_TYPE: Record<string, LinkType> = {
  'Custom Link': LinkType.CustomLink,
  'Provider Defaults (Google Meet, Teams, etc.)': LinkType.GoogleMeet,
};

const LINK_TYPE_TO_DISPLAY_NAME = flipObject(DISPLAY_NAME_TO_LINK_TYPE);

export const VirtualMeetings = () => {
  const { data: preferences } = usePreferences();
  const { mutate: updatePreferences, isPending: isUpdatingPreferences } = useUpdatePreferences({
    onSuccess: () => {
      track({ type: AnalyticsEventName.PreferencesUpdated, data: { preference: 'linkType' } });
      notifications.show({ message: 'Virtual meetings updated', color: 'green', autoClose: 3000 });
      form.resetDirty();
    },
  });
  const [linkType, setLinkType] = useState<LinkType | null>(null);

  // Setting link type to the link type on preferences on initial load
  useEffect(() => {
    if (preferences?.linkType && !linkType) {
      setLinkType(preferences.linkType);
    }
  }, [preferences?.linkType, linkType]);

  const schema = z.object({
    link: z.string(),
    linkName: z.string().min(2, { message: 'Link name should have at least 2 letters' }),
  });

  const form = useForm({
    initialValues: {
      link: preferences?.link || '',
      linkName: preferences?.linkName || '',
    },
    validate: zodResolver(schema),
  });

  const handleLinkTypeChange = (value: string | null) => {
    if (!value || !preferences) {
      // This should never happen, throwing an error
      throw new Error('Value or preferences not found');
    }

    const valueLinkType = DISPLAY_NAME_TO_LINK_TYPE[value];
    setLinkType(valueLinkType);

    if (valueLinkType !== LinkType.CustomLink) {
      updatePreferences({ ...preferences, linkType: valueLinkType, link: '', linkName: '' });
      return;
    }
  };

  const handleSubmit = (values: typeof form.values) => {
    if (!linkType || !preferences) {
      return;
    }
    updatePreferences({ ...preferences, link: values.link, linkName: values.linkName, linkType: linkType });
  };

  if (!linkType) return null;

  return (
    <SettingsGrid
      Icon={IconDeviceDesktop}
      title="Virtual Meetings"
      description="Choose your preferred platform for virtual meetings."
      left={
        <Stack>
          <Select
            data={Object.keys(DISPLAY_NAME_TO_LINK_TYPE)}
            value={LINK_TYPE_TO_DISPLAY_NAME[linkType]}
            onChange={handleLinkTypeChange}
            allowDeselect={false}
          />
          {linkType === LinkType.CustomLink && (
            <form onSubmit={form.onSubmit(handleSubmit)}>
              <Stack>
                <TextInput
                  label="Link Name"
                  placeholder='Title your link (eg: "Zoom")'
                  {...form.getInputProps('linkName')}
                />
                <TextInput label="URL Link" placeholder="https://zoom.us/..." {...form.getInputProps('link')} />
                {(form.isDirty() || linkType !== preferences?.linkType) && (
                  <Button loading={isUpdatingPreferences} type="submit">
                    Save
                  </Button>
                )}
              </Stack>
            </form>
          )}
        </Stack>
      }
    />
  );
};
