import {
  IconAdjustments,
  IconAdjustmentsFilled,
  IconCalendar,
  IconCalendarFilled,
  IconLocation,
  IconLocationFilled,
  IconProps,
  IconTestPipe,
} from '@tabler/icons-react';

import { FilledSquaresIcon } from '@components/FilledSquaresIcon';

import { PRIVATE_ROUTES } from './enums';

interface RouteDetails {
  title: string;
  icon: React.ComponentType<IconProps>;
  activeIcon?: React.ComponentType<IconProps>;
  hidden?: boolean;
}

export const ROUTES_TO_ROUTE_DETAILS: Record<PRIVATE_ROUTES, RouteDetails> = {
  [PRIVATE_ROUTES.AVAILABILITY]: {
    title: 'Availability',
    icon: FilledSquaresIcon,
  },
  [PRIVATE_ROUTES.SETTINGS]: {
    title: 'Settings',
    icon: IconAdjustments,
    activeIcon: IconAdjustmentsFilled,
  },
  [PRIVATE_ROUTES.CALENDARS]: {
    title: 'Accounts',
    icon: IconCalendar,
    activeIcon: IconCalendarFilled,
  },
  [PRIVATE_ROUTES.BETA]: {
    title: 'Beta',
    icon: IconTestPipe,
    hidden: true,
  },
  [PRIVATE_ROUTES.PLACES]: {
    title: 'Places',
    icon: IconLocation,
    activeIcon: IconLocationFilled,
  },
};
