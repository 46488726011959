import { DateTime } from 'luxon';

export interface LinkInfo {
  label: string;
  to: string;
}

export interface GooglePlaceInfo {
  placeId: string;
  description: string;
}

export const DayOfWeek = {
  Monday: '1', // 1 is Monday
  Tuesday: '2',
  Wednesday: '3',
  Thursday: '4',
  Friday: '5',
  Saturday: '6',
  Sunday: '7', // 7 is Sunday
} as const;

export type DayOfWeek = (typeof DayOfWeek)[keyof typeof DayOfWeek];

export interface TimeWindowResponse {
  start: string;
  end: string;
}

export interface TimeWindow {
  start: DateTime;
  end: DateTime;
}
