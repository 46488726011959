import type { DateInput, EventApi, EventInput } from '@fullcalendar/core';
import { DateTime } from 'luxon';
import { v4 as uuidv4 } from 'uuid';

import { DayOfWeek } from '@/types';

import { Availability, AvailabilityBlock, AvailabilityBlockType, WorkingHourSettings } from '../types';

export interface AvaibilityEvent extends EventInput {
  extendedProps: {
    type: AvailabilityBlockType;
  };
}

const getDateTimeFromTimeAndDay = (time: string, day: DayOfWeek): DateTime => {
  const now = DateTime.now();
  const weekday = now.startOf('week').plus({ days: parseInt(day, 10) - 1 });
  const [hour, minute] = time.split(':');
  return weekday.set({ hour: parseInt(hour), minute: parseInt(minute) });
};

export const convertWorkingHoursToBusinessHours = (workingHours: WorkingHourSettings): EventInput[] => {
  return Object.entries(workingHours)
    .map(([day, workingHour]) => {
      // A day without working hours will have an array of length 0, all other days will have 2 elements (start and end)
      if (workingHour.length !== 2) return;
      const [start, end] = workingHour;
      return {
        daysOfWeek: [parseInt(day) % 7],
        startTime: start,
        endTime: end,
      };
    })
    .filter((workingHour) => workingHour !== undefined) as EventInput[];
};

/**
 * converts Availability to events that can be rendered on the AvailabilityCalendar.tsx
 */
export const convertAvailabilityToEvents = (availability: Availability): AvaibilityEvent[] =>
  Object.entries(availability)
    .map(([day, events]: [string, Availability[keyof Availability]]) => {
      const dayIndex = parseInt(day, 10);
      return events.map((event) => convertAvailabilityBlockToEvent(dayIndex, event));
    })
    .flat()
    .filter((event: AvaibilityEvent | undefined): event is AvaibilityEvent => event !== undefined);

export const eventMetadataFromType = (type: AvailabilityBlockType) => {
  switch (type) {
    case AvailabilityBlockType.AVAILABLE:
      return {
        title: 'Preferred',
        extendedProps: { type },
      };
    case AvailabilityBlockType.NOT_PREFERRED:
      return {
        title: 'If You Must',
        extendedProps: { type },
      };
  }
};

const convertAvailabilityBlockToEvent = (dayIndex: number, block: AvailabilityBlock): AvaibilityEvent | undefined => {
  const { start, end } = block;
  if (!start || !end) return;

  const startTime = getDateTimeFromTimeAndDay(start, dayIndex.toString() as DayOfWeek);
  const endTime = getDateTimeFromTimeAndDay(end, dayIndex.toString() as DayOfWeek);
  return {
    ...eventMetadataFromType(block.type),
    start: startTime.toJSDate(),
    end: endTime.toJSDate(),
    id: uuidv4(),
  };
};

/**
 * Converts EventApi objects to Availability.
 * Only supports events whose start and end are on the same day.
 */
export const convertEventsToAvailability = (events: (AvaibilityEvent | EventApi)[]): Availability =>
  events
    .sort((a, b) => (a.start! < b.start! ? -1 : 1))
    .reduce<Availability>(
      (availability, event) => {
        const start = DateTime.fromJSDate(event.start! as Date);
        const end = DateTime.fromJSDate(event.end! as Date);
        const dayIndex = start.weekday.toString() as DayOfWeek;
        const startTime = start.toFormat('HH:mm');
        const endTime = end.toFormat('HH:mm');
        availability[dayIndex].push({ start: startTime, end: endTime, type: event.extendedProps.type });
        return availability;
      },
      {
        '1': [],
        '2': [],
        '3': [],
        '4': [],
        '5': [],
        '6': [],
        '7': [],
      },
    );

export const convertDateInputToDateTime = (dateInput: DateInput) => {
  return DateTime.fromJSDate(dateInput as Date);
};

/**
 * Events should merge if they border each other or overlap and the title is the same.
 */
export const checkIfEventsShouldMerge = (originalEvent: AvaibilityEvent, newEvent: EventInput) => {
  const { end: end1, start: start1 } = originalEvent;
  const { end: end2, start: start2 } = newEvent;

  if (!end1 || !start1 || !end2 || !start2) return false;
  return (
    convertDateInputToDateTime(end1) >= convertDateInputToDateTime(start2) &&
    convertDateInputToDateTime(start1) <= convertDateInputToDateTime(end2) &&
    originalEvent.title === newEvent.title
  );
};

/**
 * Events should not merge if they have a different title.
 */
export const checkIfEventsOverlapAndShouldNotMerge = (originalEvent: AvaibilityEvent, newEvent: EventInput) => {
  const { end: end1, start: start1 } = originalEvent;
  const { end: end2, start: start2 } = newEvent;

  if (!end1 || !start1 || !end2 || !start2) return false;
  return (
    convertDateInputToDateTime(end1) > convertDateInputToDateTime(start2) &&
    convertDateInputToDateTime(start1) < convertDateInputToDateTime(end2) &&
    originalEvent.title !== newEvent.title
  );
};

export const doOtherEventsExistOnSameDay = (events: EventApi[], event: EventInput) => {
  if (!event.start || !event.end) return false;

  const startDay = convertDateInputToDateTime(event.start).weekday;
  const endDay = convertDateInputToDateTime(event.end).weekday;
  return events.some((currEvent) => {
    return (
      DateTime.fromJSDate(currEvent.start as Date).weekday <= endDay &&
      DateTime.fromJSDate(currEvent.end as Date).weekday >= startDay
    );
  });
};

export const isEventOnOneDay = (event: EventInput) => {
  return convertDateInputToDateTime(event.start!).weekday === convertDateInputToDateTime(event.end!).weekday;
};

export const areAvailabilitiesEqual = (availability1: Availability, availability2: Availability) =>
  (Object.entries(availability1) as [DayOfWeek, Availability[DayOfWeek]][]).every(
    ([day, availabilityWindows]) =>
      availabilityWindows.every(
        (event, index) =>
          event.start === availability2[day][index]?.start &&
          event.end === availability2[day][index]?.end &&
          event.type === availability2[day][index]?.type,
      ) && availabilityWindows.length === availability2[day].length,
  );

export const isEventOutsideWorkingHours = (event: EventInput, workingHours: WorkingHourSettings) => {
  const { start, end } = event;
  if (!start || !end) return false;
  const dayIndex = convertDateInputToDateTime(start).weekday;
  const day = dayIndex.toString() as DayOfWeek;

  const workingHoursForDay = workingHours[day];

  if (workingHoursForDay?.length !== 2) return true;

  const startTime = getDateTimeFromTimeAndDay(workingHoursForDay[0], day);
  const endTime = getDateTimeFromTimeAndDay(workingHoursForDay[1], day);

  return convertDateInputToDateTime(end) > endTime || convertDateInputToDateTime(start) < startTime;
};
